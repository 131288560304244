import React from "react";
import ReactDOM from "react-dom"; // Correct import
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import LandingPage from "./LandingPage/LandingPage";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LandingPage />
  </React.StrictMode>
);

reportWebVitals();
