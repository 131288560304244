import "./LandingPage.css";

function TosPage() {
  return (
    <>
      <div className="op_container_box">
        <div className="op_header_box">
          <p>terms of sms</p>
        </div>
        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>acceptance of terms</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                By accessing or using the services provided by the YAP ("App"),
                you agree to comply with and be bound by these Terms of Service.
                If you disagree with any part of the terms, you may not access
                the App.
              </p>
            </div>
          </div>
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>terms</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>TERMS OF SMS Acceptance of Terms</p>
              <p>
                By accessing or using the services provided by the YAP ("App"),
                you agree to comply with and be bound by these Terms of Service.
                If you disagree with any part of the terms, you may not access
                the App. Terms
              </p>
              <p>
                1. Users can cancel the SMS service at any time by texting
                "STOP". After a user sends the SMS message "STOP", YAP will send
                the user an SMS message to confirm that they have been
                unsubscribed. After this, the user will no longer receive SMS
                messages from us. If the user wants to join again, they are able
                to sign up again and we will continue sending SMS messages.
              </p>
              <p>
                2. If a user is experiencing issues with our messaging program,
                the user can get help directly by emailing{" "}
                <a href="mailto:team@theyap.app">team@theyap.app</a>
              </p>
              <p>
                3. Carriers are not liable for delayed or undelivered messages.
              </p>
              <p>
                4. Message and data rates may apply for any messages sent to the
                user from YAP and to YAP from the user. If a user has any
                questions about their text plan or data plan, they should
                contact their wireless provider.
              </p>
              <p>
                5. Message frequency varies, but users should expect to receive
                12 messages in total communicating about the app
              </p>
              <p>
                6. If you have any questions regarding privacy, please read our
                privacy policy.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TosPage;
