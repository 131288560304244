import "./LandingPage.css";

function PrivacyPolicy() {
  return (
    <>
      <div className="op_container_box">
        <div className="op_header_box">
          <p>privacy policy</p>
        </div>
        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>introduction</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Yap is a mobile app designed to compliment your friends. Users
                participate in polls about their friends, and when a friend is
                selected in a poll, they receive a notification. As Yap is
                specifically designed for teenagers, we place a high emphasis on
                safety and user privacy. We limit the data we collect to what is
                necessary for app use and friend connection. Below, we outline
                the data we collect, its usage and sharing, and your rights.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>data collection And use</p>
            </div>
            <div className="op_inner_container_para_box"></div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>account data</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                When you sign up for an account on Yap, you are asked to enter
                your phone number, name, school, grade level, gender, username,
                age, and profile photo. This data is used to identify you to
                other Yap users and makes it easy for your friends to find your
                account on the service. If you provide your school, you will be
                listed in the classmate section of the app and your classmates
                will be able to request to be friends with you.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>contacts data</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                When signing up for an account on Yap, you have the option to
                give Yap access to your address book to find friends on Yap. We
                will use your Contacts in order to identify other Users that you
                may know or that your Contacts may know and to provide you and
                other Users with suggestions with whom to connect, such as
                friends-of-friends. We may also present your Contacts as options
                in polls if you do not have at least 12 friends on the app.
                Choosing Contacts in polls does not send them an invitation to
                join Yap. Yap may access the contact list available on your
                mobile device to invite your contacts to join Yap. We may send a
                one-time text message on your behalf to your contacts. Please
                understand that we do not use your contact list information for
                any purpose other than to send invitations or messages on your
                behalf and with your explicit consent. You can invite friends to
                join Yap by sending them a text message with a link to install
                the app. The invitation may include your school name and your
                friends' names on Yap.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>location data</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Before creating an account on Yap, you have to give Yap access
                to your approximate location data. We will use your approximate
                location to suggest nearby schools and friends to join and to
                determine if Yap is available in your area. Your approximate
                location data is not stored or linked to your identity and we do
                not track your location after your account is created.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>device and app activity data</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                When you use the app, we automatically collect certain
                information about your device (such as the type of device,
                operating system, IP address, and unique identifiers) and how
                you interact with the app (such as the actions you take and how
                long you use the app). We use this information to improve the
                app, create new features, and keep our systems secure.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>how we disclose data</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We do not sell, rent, license, or lease your data to third
                parties, nor do we share it with third parties for cross-context
                behavioral advertising. However, we may disclose it to the
                following third parties: * Service Providers – such as hosting
                services, customer support services, advertisement platforms and
                analytics services. * Professional Advisors – such as lawyers
                and accountants. * Business Transaction Recipients – including
                counterparties and others assisting with a merger, acquisition,
                financing, reorganization, bankruptcy, receivership,
                dissolution, asset sale, or similar transaction involving our
                business. * Legal and Safety – government authorities or
                regulators or other third parties where required by law or legal
                process or to protect Yap, users, or other third parties rights
                and safety.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>how you share data</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                You can use Yap to answer polls about your friends. When
                answering a poll, we send your response to your friend via the
                App and it includes your grade, gender, and the other poll
                options, which could include friends or contacts. Your friend
                may reveal your name if they subscribe to additional services.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>data retention</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We keep your data until we don’t need it for the purposes
                described in this policy and we have no legal obligation to keep
                it.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>your rights</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We provide all users with the following rights: * Deactivate –
                you can deactivate your account so that you will not be able to
                send or receive new polls; * Know – you can request to know the
                categories of data we have collected about you, the categories
                of sources from which we collected it, the purposes for which we
                collected it, and the categories of third parties to whom we
                disclosed it; * Access – you can download the data we have about
                you, where it is required by law; * Delete – you can request
                that we delete the data we have about you, but this right is not
                absolute. We may need to keep certain information, for example,
                to comply with our legal requirements; * Correct – you can ask
                that we fix any errors or inaccuracies in the data we hold about
                you; * Stop processing – you can tell us to stop processing your
                data; * Object – you can object to how we process your data; and
                * Complain – you can complain to the data protection regulator
                where you live (if you have one). You can exercise these rights
                by tapping “help & support ” in the app, emailing us at
                team@theyap.app and providing the username and phone number
                associated with your account in your message. We will not treat
                you differently if you exercise any of these rights, but we may
                need additional information to verify your identity. An agent
                can also make these requests on your behalf, but we may still
                ask you to verify your identity and that you have authorized the
                agent to act on your behalf. Depending on your location, you may
                have the right to appeal our decision regarding a request
                related to the above rights, in which case, you may do so by
                contacting us using the above contact information. Your browser
                settings may allow you to transmit a “do not track” signal,
                “opt-out preference” signal, or other mechanism for exercising
                your choice regarding the collection of your data when you visit
                various websites and online services. Our website and app are
                not designed to respond to such signals, and we do not use or
                disclose your data in any way that would legally require us to
                recognize opt-out preference signals.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>categories</p>
            </div>
            <div className="op_inner_container_para_box"></div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>categories of recipients</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                * Identifiers, such as name and IP address – Service Providers *
                Personal information categories listed in the California
                Customer Records statute, such as name and telephone number –
                Service Providers * Characteristics of protected classifications
                under California or federal law, such as gender – Service
                Providers * Commercial information, such as records of products
                or services purchased – Service Providers * Internet or other
                similar network activity, such as information regarding your
                interaction with the app – Service Providers * Geolocation data,
                such as approximate location – Service Providers * Audio,
                electronic, visual, thermal, olfactory, or similar information,
                such as profile photos – Service Providers The specific business
                or commercial purposes for which we collect your personal
                information and the categories of sources from which we collect
                your personal information are described in the section above,
                Data Collection And Use. When determining the length of time to
                retain your personal information, we consider various criteria,
                including whether we need the information to continue to provide
                you the app, resolve a dispute, enforce our contractual
                agreements, prevent harm, promote safety, security and
                integrity, or protect ourselves, including our rights.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>children</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Children under 13 are prohibited from using Yap. We do not seek
                or knowingly collect any data from children under 13. If we
                learn that we have collected data from children without proper
                consent, we will delete that data. If you believe we have
                collected data from a user under 13, or if you are a parent or
                guardian of a user under 13 who has provided us with data,
                please email us at team@theyapp.app with information about the
                account to request that it be deleted.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>changes to this policy</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We may need to update this policy to account for changes in our
                business and applicable laws. If we change this policy, we will
                post the new version here. If the changes are significant we
                will notify you through the app.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Data Tracking and Third-Party Sharing</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We may collect data such as your device type, operating system,
                and app usage patterns, and with your consent, track your
                activity across other apps and websites to provide targeted ads.
                We may share this data with third parties, including advertising
                partners to deliver personalized ads, and service providers or
                analytics tools to improve the App's performance. You have the
                option to opt out of tracking by adjusting your app or device
                settings.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>contact us</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Please email us at{" "}
                <a href="mailto:team@theyap.app">team@theyap.app</a> with any
                questions.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default PrivacyPolicy;
