import "./LandingPage.css";

function StatementOfAppliance() {
  return (
    <>
      <div className="op_container_box">
        <div className="op_header_box">
          <p>statement of compliance</p>
        </div>
        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>introduction</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                This document outlines the compliance measures taken by Yap
                under Rule 3(1) of the Information Technology (Intermediary
                Guidelines and Digital Media Ethics Code) Rules, 2021
                (Intermediary Guidelines, 2021).
              </p>
            </div>
          </div>
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>social media intermediary</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Yap is classified as a Social Media Intermediary under the
                Intermediary Guidelines 2021 and has implemented policies and
                procedures to ensure compliance with the requirements of these
                guidelines.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>due diligence measures</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                The Terms of Service & Privacy Policy are available on its
                website as well as accessible within the Yap App. Any changes to
                the same are informed at least once in a calendar year. As part
                of the Privacy Policy & Terms of Service, users are informed
                that Yap reserves the right to terminate access or usage rights
                of the users at any time and may remove non-compliant
                information or both as the case may be.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>prohibited content</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                In addition to other requirements prescribed by the Rules, the
                Terms of Service & Privacy Policy specifically prohibit users
                from publishing content that:
              </p>
              <p></p>
              <p>
                1. Is invasive of another’s bodily privacy or harmful or
                harassing on the basis of gender, especially concerning
                children.
              </p>
              <p>2. Is patently false or misleading but appears as a fact.</p>
              <p>
                3. Impersonates or deceives or is false with the intention of
                causing injury, wrongful loss, harassment, wrongful gain, or
                fraud.
              </p>
              <p>
                4. Threatens the unity, integrity, defense, or violates any law.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default StatementOfAppliance;
