import React from "react";
import "./LandingPage.css";
import appLogo from "../assets/app_logo.svg";
import instaLogo from "../assets/insta_logo.svg";
import xLogo from "../assets/x_logo.svg";
import snapLogo from "../assets/snap_logo.svg";
import tiktokLogo from "../assets/tiktok_logo.svg";
import MainPart from "./MainPart";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  Navigate,
  useNavigate,
} from "react-router-dom";
import TosPage from "./TosPage";
import TermsOfServicePage from "./TermsOfService";
import PrivacyPolicy from "./PrivacyPolicy";
import StatementOfAppliance from "./StatementOfAppliance";
import Faqpage from "./faq";

const EmailRedirect = () => {
  const navigate = useNavigate();

  return (
    <div className="op_container_box">
      <div className="op_header_box">
        <p>Email us</p>
      </div>
      <div className="op_inner_container_body">
        <div className="op_inner_container_box">
          <div className="op_inner_container_heading_box">
            <p>Click here to email us:</p>
          </div>
          <div className="op_inner_container_para_box">
            <p>
              <a href="mailto:team@theyap.app">team@theyap.app</a>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

function LandingPage() {
  return (
    <Router>
      <div className="main_container">
        <div className="header_box">
          <div className="img_box">
            <a href="/">
              <img src={appLogo} alt="yap" />
            </a>
          </div>
        </div>

        <Routes>
          <Route path="/" element={<MainPart />} />
          <Route path="/privacy_policy" element={<PrivacyPolicy />} />
          <Route path="/terms_of_service" element={<TermsOfServicePage />} />
          <Route path="/soc" element={<StatementOfAppliance />} />
          <Route path="/terms_of_sms" element={<TosPage />} />
          <Route path="/faq" element={<Faqpage />} />
          <Route path="/email_us" element={<EmailRedirect />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>

        <div class="overlay"></div>

        <div className="footer_box">
          <div className="footer_1">
            <a href="/privacy_policy">privacy policy</a>
            <a href="/terms_of_service">terms of service</a>
            <a href="/soc">soc</a>
            <a href="/terms_of_sms">terms of sms</a>
            <a href="/faq">faq</a>
            <a href="mailto:team@theyap.app" target="_top">
              email us
            </a>
          </div>
          <div className="footer_2">
            <a
              href="https://www.instagram.com/teamyap.app?igsh=MTNuNWoyYTBwdzU3Mg=="
              target="_blank"
            >
              <img src={instaLogo} alt="Instagram" />
            </a>
            <a href="https://www.tiktok.com/@yapteam" target="_blank">
              <img src={tiktokLogo} alt="TikTok" />
            </a>
            <a href="https://x.com/theyap_app" target="_blank">
              <img src={xLogo} alt="X" />
            </a>
            <a
              href="https://www.snapchat.com/add/theyapapp?share_id=CmYtGr1lIXM&locale=en-GB"
              target="_blank"
            >
              <img src={snapLogo} alt="Snapchat" />
            </a>
          </div>
        </div>
      </div>
    </Router>
  );
}

export default LandingPage;
