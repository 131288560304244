import "./LandingPage.css";

function TermsOfServicePage() {
  return (
    <>
      <div className="op_container_box">
        <div className="op_header_box">
          <p>terms of service</p>
        </div>
        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              {/* <p>acceptance of terms</p> */}
            </div>
            <div className="op_inner_container_para_box">
              <p>Thank you for using Yap</p>
              <p>
                Welcome to Yap. Yap is an app to compliment your friends. We
                hope Yap shows you that there are people who love and admire
                you.
              </p>
              <p>
                These Terms of Service (“Terms”) govern access and use of our
                app, website, and other tools (collectively, “Yap” or the
                “Service” or “Services”). Each time you access or use Yap, you
                agree to be bound by these Terms and any additional terms that
                apply to you. If you do not agree to be bound by these Terms,
                please stop using Yap.
              </p>
              <p>
                We also have a Privacy Policy that discusses what information we
                collect and how we use this information. You should read the
                Privacy Policy because it governs the YAP’s use of your personal
                information.
              </p>
              <p>
                IMPORTANT NOTE THE SECTION TITLED “ARBITRATION AND GOVERNING
                LAW” CONTAINS AN ARBITRATION CLAUSE AND CLASSACTION WAIVER THAT
                APPLIES TO ALL U.S.BASED YAP USERS. PLEASE READ THIS SECTION
                CAREFULLY AS IT MAY SIGNIFICANTLY AFFECT YOUR LEGAL RIGHTS,
                INCLUDING YOUR RIGHT TO FILE A LAWSUIT IN COURT. YOU HAVE THE
                RIGHT TO OPT OUT OF THE ARBITRATION CLAUSE AND THE CLASS ACTION
                WAIVER AS EXPLAINED IN SECTION THE SECTION TITLED “ARBITRATION
                AND GOVERNING LAW.”
              </p>
            </div>
          </div>
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>age and legal capacity</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                You represent that you are at least 13 years of age and possess
                the legal capacity to enter into the agreement set forth in
                these Terms. No one under the age of 13 is allowed to use or
                access Yap. If you are old enough to use or access Yap but are
                not old enough to have authority to consent to our terms, your
                parent or guardian must agree to these Terms on your behalf. If
                you’re a parent or legal guardian, and you allow your teenager
                to use the services, then these Terms also apply to you and
                you’re responsible for your teenager’s activity on the services.
              </p>
              <p>
                If you are a resident of the European Economic Area (“EEA”), you
                may only use Yap if you are over the age at which you can
                provide consent to data processing under the laws of your
                country or if verifiable parental consent for your use of Yap
                has been provided to us.
              </p>
            </div>
          </div>
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>accounts and software updates</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                When you create your Yap account, you agree that the information
                you submit is correct and complete. We may refuse you access to
                the Service if we learn that the information you provided is
                inaccurate. You may stop using the Services at any time and for
                any reason. You can delete your Yap account by tapping on
                settings › manage your account › delete/deactivate account.
              </p>
              <p>
                Using Yap requires that we initially download software to your
                computer, phone, tablet, or other device. You agree that we may
                automatically update that software and that these Terms and our
                policies will apply to any updates.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>your license to use yap</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                The Services, including their “look and feel” (e.g., text,
                graphics, images, logos), proprietary content, information and
                other materials, and the proprietary software and other
                technology used to provide the Services are protected under
                copyright, trademark and other intellectual property laws. You
                agree that the Yap and/or its licensors own all right, title and
                interest in and to the Services (including any and all
                intellectual property rights therein) and you agree not to take
                any action(s) inconsistent with such ownership interests.
                Although we are granting you this license, we and our licensors
                retain all intellectual property rights we have in our software
                and Services (other than User Content (defined below)),
                including, without limitation, the exclusive right to create
                derivative works.
              </p>
              <p>
                The Yap name and all related names, logos, product and service
                names, designs and slogans are trademarks or its affiliates or
                licensors. The unauthorised use of any of these trademarks is
                strictly prohibited.
              </p>
              <p>
                Some of our Services allow you to download client software. So
                long as you comply with these Terms, we grant you a worldwide,
                non-exclusive, personal, non-assignable and revocable license to
                download, install, and run that software, solely to access our
                Services.
              </p>
              <p>
                You may not copy, modify, create derivative works based upon,
                distribute, sell, lease, or sublicense any of our software or
                Services. You also may not reverse engineer or decompile our
                software or Services, attempt to do so, or assist anyone in
                doing so, unless you have our written consent or applicable law
                permits it.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>you own what you post</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We refer to the photos, comments, links, and other content you
                post as ”User Content.” You retain all rights in and are solely
                responsible for the User Content you post to Yap. While your
                User Content belongs to you, several important limitations
                apply: First, User Content does not include your Yap username.
                Should you or Yap terminate your account, you do not retain any
                rights to the username you used. A new Yap user may use the user
                name in the future. Second, you grant Yap and our users a
                non-exclusive, royalty-free, transferable, sublicensable,
                worldwide license to use, store, display, reproduce, save,
                modify, create derivative works, perform, and distribute your
                User Content for the purposes of using, accessing, operating,
                developing and providing Yap. Nothing in these Terms restricts
                other legal rights Yap may have to User Content, for example
                under other licenses. Third, we retain the right to remove or
                modify User Content or change the way it’s used in Yap, for any
                reason. This includes User Content that in our judgment violates
                these Terms or any other policies. Fourth, content you share
                with others on Yap may continue to exist on Yap even if you
                leave Yap. We may backup, archive and retain your User Content
                even if you terminate or deactivate your account, or delete or
                remove specific User Content. Yap and its users may retain and
                continue to use, store, display, reproduce, re-pin, modify,
                create derivative works, perform, and distribute any of your
                User Content that other users have stored or shared on Yap. If
                you post or visit a link posted on Yap We may allow you to post
                links to third-party websites, information, materials, products,
                or services. Please do not post links in violation of these
                Terms. Yap does not control nor endorse or assume any
                responsibility for these links. If you access any third party
                website, service, or content from Yap, you do so at your own
                risk. You accept and agree that Yap has no liability arising
                from your use of or access to any third party website, service,
                or content. If you provide us with feedback We appreciate user
                input on ways to make Yap better. If you make any comments or
                suggestions to Yap, you grant us a non-exclusive, perpetual,
                irrevocable, transferable, sublicensable license to use the
                feedback and ideas, content and technology generated from the
                feedback without any restrictions, attribution, or compensation
                to you. Yap doesn’t waive any rights to use similar or related
                feedback provided to Yap before or after your feedback, or
                developed by Yap’s employees, or obtained from sources other
                than you. Keeping your account secure While we cannot guarantee
                your account from hacking by an unauthorized party, we employ
                security measures to keep your account and content secure. You
                can help us by keeping your SMS authentication code to yourself.
                Let us know right away of any unauthorized use or compromise to
                your account. Conditions of Access and Use User Conduct You are
                solely responsible for all code, video, images, information,
                data, text, software, music, sound, photographs, graphics,
                messages, and other materials (“content”) that you make
                available to the Company, including by uploading, posting,
                publishing, or displaying (hereinafter, “upload(ing)”) via the
                Service or by emailing or otherwise making available to other
                users of the Service). The following are examples of the kinds
                of content and/or uses that are illegal or prohibited by the
                Company. Company reserves the right to investigate and take
                appropriate legal action against anyone who, in the Company’s
                sole discretion, violates this provision, including removing the
                offending content from the Service, suspending or terminating
                the account of such violators, and reporting the violator to law
                enforcement authorities. You agree to not use the Service to:
                email or otherwise upload any content that (i) infringes any
                intellectual property or other proprietary rights of any party;
                (ii) you do not have a right to upload under any law or under
                contractual or fiduciary relationships; (iii) contains software
                viruses or any other computer code, files or programs designed
                to interrupt, destroy, or limit the functionality of any
                computer software or hardware or telecommunications equipment;
                (iv) poses or creates a privacy or security risk to any person;
                (v) constitutes unsolicited or unauthorized advertising,
                promotional materials, commercial activities and/or sales, “junk
                mail,” “spam,” “chain letters,” “pyramid schemes,” “contests,”
                “sweepstakes,” or any other form of solicitation; (vi) is
                unlawful, harmful, threatening, abusive, harassing, tortious,
                excessively violent, defamatory, vulgar, obscene, pornographic,
                libelous, invasive of another’s privacy, hateful,
                discriminatory, or otherwise objectionable; or (vii) in the sole
                judgment of Company, is objectionable or which restricts or
                inhibits any other person from using or enjoying the Service, or
                which may expose Company or its users to any harm or liability
                of any type; interfere with or disrupt the Service or servers or
                networks connected to the Service, or disobey any requirements,
                procedures, policies, or regulations of networks connected to
                the Service; violate any applicable local, state, national, or
                international law, or any regulations having the force of law;
                impersonate any person or entity, or falsely state or otherwise
                misrepresent your affiliation with a person or entity; solicit
                personal information from anyone under the age of 18; harvest or
                collect email addresses or other contact information of other
                users from the Service by electronic or other means for the
                purposes of sending unsolicited emails or other unsolicited
                communications; advertise or offer to sell or buy any goods or
                services for any business purpose that is not specifically
                authorized; further or promote any criminal activity or
                enterprise or provide instructional information about illegal
                activities obtain or attempt to access or otherwise obtain any
                content or information through any means not intentionally made
                available or provided for through the Service; circumvent,
                remove, alter, deactivate, degrade, or thwart any of the content
                protections in or geographic restrictions on any content
                (including Service Content (as defined below)) available on or
                through the Service, including through the use of virtual
                private networks; or engage in or use any data mining, robots,
                scraping, or similar data gathering or extraction methods. If
                you are blocked by Company from accessing the Service (including
                by blocking your IP address), you agree not to implement any
                measures to circumvent such blocking (e.g., by masking your IP
                address or using a proxy IP address or virtual private network).
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>service content</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                You acknowledge and agree that the Service may contain content
                or features (“Service Content”) that are protected by copyright,
                patent, trademark, trade secret, or other proprietary rights and
                laws. Except as expressly authorized by the Company, you agree
                not to modify, copy, frame, scrape, rent, lease, loan, sell,
                distribute, or create derivative works based on the Service or
                the Service Content, in whole or in part, except that the
                foregoing does not apply to your own User Content that you
                upload to or make available through the Service in accordance
                with these Terms of Service. Any use of the Service or the
                Service Content other than as specifically authorized herein is
                strictly prohibited.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>competitors</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                No employee, independent contractor, agent, or affiliate of any
                competing social media company is permitted to view, access, or
                use any portion of the Service without express written
                permission from the Company. By viewing, using, or accessing the
                Service, you represent and warrant that you are not a competitor
                of the Company or any of its affiliates, or acting on behalf of
                a competitor of the Company in using or accessing the Service.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>commercial use</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Unless otherwise expressly authorized herein or in the Service,
                you agree not to display, distribute, license, perform, publish,
                reproduce, duplicate, copy, create derivative works from,
                modify, sell, resell, exploit, transfer, or upload for any
                commercial purposes, any portion of the Service, use of the
                Service, or access to the Service. The Service is for your
                personal use.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Subscriptions</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>Automatic Renewal and Cancellation :-</p>
              <p>
                Yap offers Users a weekly subscription known as premium for an
                enhanced user experience. This can be purchased by tapping the
                premium button from the profile screen and See Who sent it on
                your Inbox. If you sign up for a subscription, your subscription
                will automatically renew until you cancel, and you will
                automatically be charged the associated fee (plus any applicable
                taxes) until you cancel. To see your next renewal date and
                current fee, visit your mobile device’s account settings, or the
                Subscriptions tab that is available through the Apple App Store.
                You must cancel your subscription at least 24 hours before the
                end of your current billing period to avoid being charged for
                the next billing period. After cancellation, you will have
                access to your subscription through the last day of your current
                billing period, at which point your subscription (and access to
                your subscription features) will be discontinued. You may cancel
                your subscription any time through your Apple App Store. To
                access the subscription features after discontinuing your paid
                subscription, you may need to purchase a new subscription,
                subject to then-current fees, terms, and conditions.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>micropayments</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Micropayments refer to small financial transactions conducted
                within the app. These transactions typically involve low
                monetary amounts and are used to facilitate various services or
                purchases within the app. Users can perform micropayments for
                features such as in-app purchases, subscription fees, access to
                premium content, and other similar services
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Paying For Your Subscription</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Payments for your subscription are processed via Apple (“App
                Provider”) and you will need to provide your App Provider with
                your payment information. You are responsible for maintaining
                accurate and up-to-date payment information. If a payment is
                unsuccessful, we may suspend your premium access to the relevant
                subscription features until your payment is successful. Yap is
                not responsible if your subscription fails to renew due to
                invalid payment information.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>refunds</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Refund requests are handled by your App Provider, not Yap. To
                request a refund, you should follow your App Provider’s refund
                procedures. Yap does not offer refunds for your subscription. If
                you cancel, we will not issue you a refund or credit for any
                partial-period access to your subscription. You are not entitled
                to a refund or credit for any part of a billing period if the
                Subscription Features are unavailable, defective, experiencing
                delays, or for any other reason.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Changes to Subscriptions and Features</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We reserve the right to change or discontinue your subscription
                plans, subscription features, terms, or pricing at any time. Yap
                is not responsible for any damage or loss caused by failures or
                delays of your subscription or the subscription features.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>sms messages</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Yap will send you SMS or short message service messages, also
                commonly known as text messages, for two-factor authentication.
                Please note that message and data rates may apply.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>help</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>To obtain help you may:</p>
              <p>Email - team@theyap.app</p>e<p>Terminating SMS</p>
              <p>
                You may terminate any SMS messages from Yap at any time by
                texting STOP.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>when you provide us your phone number</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                In order to use the Services, you must opt-in by providing us
                with your phone number (your “Phone Number”) through the Site
                (“Opt-in”). An invite will then be sent to your Phone Number via
                SMS message to register for an Account. In addition, if you are
                not registered for our Services, other Users may send you a SMS
                message through the App inviting you. You can block messages
                from Yap and other communications in connection with our
                Services at any time by texting 'STOP' to the number that texted
                you and we will send you a reply confirming your request to
                unsubscribe. After unsubscribing, you will no longer receive
                messages from us. In the event you unsubscribe and then choose
                to use the Services and register for an Account, you will need
                to Opt in again and we will send you an invite to register via
                SMS message. If at any time you forget what keywords are
                supported, you can text "HELP" to the number that texted you and
                we will respond with instructions on how to use the Service as
                well as how to unsubscribe. We are able to deliver SMS messages
                to the mobile phone carriers listed above. You understand,
                acknowledge and agree that (i) text message and data rates may
                apply for any SMS messages sent to you from us, from you to us
                or between Users; (ii) you are solely responsible for any fees
                charged by your wireless provider in connection with SMS
                messages that you receive or send in connection with the Opt-in
                and/or the Services; and (iii) you will contact your wireless
                provider with any questions regarding your text and/or data
                plan. Once you receive an invite via SMS message and if you
                choose to continue the use of the Services, you will be required
                to register for an account through the App by providing us with
                certain information such as your name, school, grade level,
                gender, phone number, contact book and photo. In registering for
                an account, you agree to (i) provide true, accurate, current and
                complete information about yourself; and (ii) maintain and
                promptly update your account to keep it true, accurate, current
                and complete. If you provide any information that is untrue,
                inaccurate, not current or incomplete, or we have reasonable
                grounds to suspect that such information is untrue, inaccurate,
                not current or incomplete, we have the right to suspend or
                terminate your Account and refuse any and all current or future
                use of the Services.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>indemnity</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                You agree to indemnify, defend and hold harmless Yap, and its
                directors, officers, employees and agents, from any and all
                legal actions, demands, claims, losses, expenses or damages
                arising out of or in any way related to your access or use of
                Yap or our Service (or any other person accessing or using Yap
                through your account), your User Content, any breach of these
                Terms or violation of our policies. In such circumstances, you
                agree we are entitled to payment from you for any judgment, our
                reasonable legal fees and all costs we incur to defend
                ourselves. Our right to indemnification survives any
                cancellation, termination or suspension of your Yap account.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>disclaimer and waiver of claims</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                WE MAKE NO REPRESENTATIONS WHATSOEVER AS TO THE ACCURACY,
                USEFULNESS, SAFETY, OR INTELLECTUAL PROPERTY RIGHTS OF ANY
                INFORMATION POSTED ON Yap OR THE Yap SOFTWARE. OUR SERVICE,
                SOFTWARE AND ALL CONTENT ON Yap IS PROVIDED “AS IS” AND “AS
                AVAILABLE” WITHOUT WARRANTIES OF ANY KIND, EITHER EXPRESS OR
                IMPLIED. YOUR USE OF Yap IS AT YOUR OWN RISK. WE SPECIFICALLY
                DISCLAIM ANY AND ALL WARRANTIES AND CONDITIONS OF
                MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE AND
                NONINFRINGEMENT, AND ANY WARRANTIES ARISING OUT OF COURSE OF
                DEALING OR USAGE OF TRADE. We do not review User Content in
                advance of its posting. Yap takes no responsibility and assumes
                no liability for any User Content that you or any other person
                or third party posts or sends using our Service. You understand
                and agree that you may be exposed to User Content that’s
                inaccurate, offensive, indecent, objectionable, inappropriate
                for children, or otherwise unsuited to your purpose. This
                disclaimer and waiver of claims survives any cancellation,
                termination or suspension of your Yap account and termination of
                these Terms.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>our liability is limited</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                TO THE MAXIMUM EXTENT PERMITTED BY LAW, YAP, AND ITS OWNERS,
                OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS SHALL NOT BE LIABLE
                UNDER ANY THEORY OF LAW OR IN EQUITY FOR ANY INCIDENTAL,
                SPECIAL, CONSEQUENTIAL, EXEMPLARY, PUNITIVE OR OTHER DAMAGES, OR
                FOR ANY BUSINESS INTERRUPTION OR ANY LOSS OF PROFITS OR
                REVENUES, WHETHER INCURRED DIRECTLY OR INDIRECTLY, OR ANY LOSS
                OF DATA, USE, GOODWILL, OR OTHER INTANGIBLE LOSSES, RESULTING
                FROM THE USE OF INABILITY TO USE OUR SERVICE OR ITS CONTENT; ANY
                CONDUCT OR CONTENT OF ANY THIRD PARTY ON THE SERVICE, INCLUDING
                WITHOUT LIMITATION, ANY DEFAMATORY, OFFENSIVE OR ILLEGAL CONTENT
                OR CONDUCT OF OTHER USERS OR THIRD PARTIES; UNAUTHORIZED ACCESS,
                USE OR ALTERATION OF YOUR TRANSMISSIONS OR CONTENT; ANY CLAIM
                ATTRIBUTABLE TO ERRORS, OMISSIONS, OR OTHER INACCURACIES IN THE
                SERVICE OR THE CONTENT; HOWSOEVER CAUSED, EVEN IF Yap HAS BEEN
                ADVISED OF THE POSSIBILITY OF SUCH DAMAGES. ANY LIABILITY FOR
                CLAIMS BROUGHT AGAINST THE COMPANY OR ANY OF ITS AFFILIATES OR
                ANY OF THEIR OWNERS, OFFICERS, DIRECTORS, EMPLOYEES OR AGENTS IS
                LIMITED TO $100.00 OR SUCH THE SMALLEST AMOUNT PERMITTED BY LAW,
                WHICHEVER IS LESSER. IF YOU ARE DISSATISFIED WITH THE SERVICE,
                THE CONTENT OR WITH THESE TERMS, YOUR SOLE AND EXCLUSIVE REMEDY
                IS TO DISCONTINUE USING THE SERVICE. If we cause damage to you
                and you're a consumer in the EEA, the above doesn’t apply.
                Instead, Yap’s liability will be limited to foreseeable damages
                arising due to a breach of material contractual obligations
                typical for this type of contract. Yap isn’t liable for damages
                that result from a non-material breach of any other applicable
                duty of care. This limitation of liability won’t apply to any
                statutory liability that cannot be limited, to liability for
                death or personal injury caused by our negligence or willful
                misconduct, or if and to exclude our responsibility for
                something we have specifically promised to you in writing. This
                limitation of liability provision survives any cancellation,
                termination or suspension of your Yap account and termination of
                these Terms.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>arbitration and governing law</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                If a dispute arises with Yap, please contact us. We will work to
                address it with you quickly and informally. Should an informal
                resolution of the dispute prove impossible, you agree that any
                dispute, claim or controversy arising out of or relating to your
                access or use of Yap or these Terms, including the determination
                of the scope or applicability of this provision, shall be
                determined by final, binding and non-appealable arbitration.
                Arbitration is a less expensive, and often quicker, means for
                parties to resolve their dispute than filing a lawsuit in a
                court of law. An independent and neutral arbitrator decides the
                case, not a judge or jury. The arbitration award is final and
                not subject to review by a court. Arbitrators, however, can
                award the same damages and relief that a court can award. You
                agree that, by agreeing to these Terms of Service, the U.S.
                Federal Arbitration Act governs the interpretation and
                enforcement of this provision and Agreement, and that you and
                Yap are each waiving the right to a trial by jury or to
                participate in a class action. The arbitrator has exclusive
                authority to resolve any dispute relating to the interpretation,
                applicability, or enforceability of this binding arbitration
                agreement. This arbitration provision shall survive the
                termination of these Terms and the termination of your Yap
                account. Any arbitration will be administered by the American
                Arbitration Association ("AAA") under the Consumer Arbitration
                Rules then in effect for the AAA, except as provided herein. You
                can find their forms at www.adr.org. Regardless of the manner in
                which the arbitration is conducted, the arbitrator shall issue a
                reasoned written decision explaining the essential findings and
                conclusions on which the award is based, and any judgment on the
                award rendered by the arbitrator may be entered in any court of
                competent jurisdiction. Nothing in this Section shall prevent
                either party from seeking injunctive or other equitable relief
                from the courts, including for matters related to data security,
                intellectual property or unauthorized access to the Service. ALL
                CLAIMS MUST BE BROUGHT IN THE PARTIES' INDIVIDUAL CAPACITY, AND
                NOT AS A PLAINTIFF OR CLASS MEMBER IN ANY PURPORTED CLASS OR
                REPRESENTATIVE PROCEEDING, AND, UNLESS WE AGREE OTHERWISE, THE
                ARBITRATOR MAY NOT CONSOLIDATE MORE THAN ONE PERSON'S CLAIMS.
                YOU AGREE THAT, BY ENTERING INTO THESE TERMS, YOU AND Yap ARE
                EACH WAIVING THE RIGHT TO A TRIAL BY JURY OR TO PARTICIPATE IN A
                CLASS ACTION. NOTHING IN THESE TERMS OF SERVICE SHALL AFFECT ANY
                NONWAIVABLE STATUTORY RIGHTS THAT APPLY TO YOU. To the extent
                any claim, dispute or controversy regarding Yap or our Service
                isn’t arbitrable under applicable laws or otherwise: you and Yap
                both agree that any claim or dispute regarding Yap will be
                resolved exclusively in a California court governed by the laws
                of the State of California, without respect to its conflict of
                laws principles. If you are not a consumer in the EEA, the
                exclusive place of jurisdiction for all disputes arising from or
                in connection with these Terms is San Francisco County,
                California, or the United States District Court for the Northern
                District of California and our dispute will be determined under
                California law. If you're a consumer in the EEA, this
                arbitration provision does not apply to you.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>opt-out</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                You can decline this agreement to arbitrate by emailing an
                opt-out notice to team@theyap.app within 30 days of when you
                first register your Yap account, otherwise you shall be bound to
                arbitrate disputes in accordance with the terms of these
                paragraphs. If you opt out of these arbitration provisions, Yap
                also will not be bound by them.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>we may modify these terms</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We are continually working to improve Yap. As a result, our
                Terms may be modified or we may publish new Terms at any time.
                Any modified and/or new Terms will become effective immediately
                upon posting. You agree that we may provide you notice of new or
                modified terms by posting them on the Yap website and/or within
                the settings of the Yap app. By continuing to access and use
                Yap, you agree to be bound by the new or modified terms.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>general provisions</p>
            </div>
            <div className="op_inner_container_para_box"></div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>assignment</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                These Terms, and any rights and licenses granted hereunder, may
                not be transferred or assigned by you but may be assigned by Yap
                without restriction. Any attempted transfer or assignment in
                violation hereof shall be null and void. If you’re a consumer in
                the EEA, either you or Yap may assign these Terms, and any
                rights and licenses granted under it, to a third party. In case
                of such an assignment by Yap, you are entitled to terminate the
                agreement with immediate effect by deactivating your account.
                Yap will provide you with reasonable notice of any such
                assignment.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>california residents</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                If you are a California resident, in accordance with Cal. Civ.
                Code § 1789.3, you may report complaints to the Complaint
                Assistance Unit of the Division of Consumer Services of the
                California Department of Consumer Affairs by contacting them in
                writing at 1625 North Market Blvd., Suite N 112 Sacramento, CA
                95834, or by telephone at 800 9525210.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>severability</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                If any provision of these Terms shall be unlawful, void, or for
                any reason unenforceable, then that provision shall be deemed
                severable from these Terms and the remaining provisions shall
                remain in full force and valid.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>no waiver</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                No waiver of any term of these Terms shall be deemed a further
                or continuing waiver of such term or any other term, and Yap's
                failure to assert any right or provision under these Terms shall
                not constitute a waiver of such right or provision.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>no waiver</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                No waiver of any term of these Terms shall be deemed a further
                or continuing waiver of such term or any other term, and Yap's
                failure to assert any right or provision under these Terms shall
                not constitute a waiver of such right or provision.
              </p>
            </div>
          </div>

          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>open Source</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Some of Yap's services include software subject to separate
                open-source license terms, and your use of those services is
                subject to your compliance with those license terms, when
                applicable. We encourage you to review them, as some licenses
                may explicitly override these Terms.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default TermsOfServicePage;
