import yapDuck from "../assets/duck.png";
import appStoreBadge from "../assets/app_store_badge.png";
import "./LandingPage.css";

function MainPart() {
  return (
    <div className="main_box">
      <div className="yap_duck_box">
        <img src={yapDuck} alt="yap duck" />
      </div>
      <div className="yap_title_box">
        <h1>be a YAPstar.</h1>
        <p>see who is yapping about you.</p>
        <div className="buttons">
          <a
            href="https://apps.apple.com/app/yap-see-who-is-yapping/id6569248005"
            target="_blank"
          >
            <img src={appStoreBadge} alt="App Store" />
          </a>
          {/* <a href="#">
            <img src="path/to/google_play_badge.png" alt="Google Play" />
          </a> */}
        </div>
      </div>
    </div>
  );
}

export default MainPart;
