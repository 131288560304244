import "./LandingPage.css";

function Faqpage() {
  return (
    <>
      <div className="op_container_box">
        <div className="op_header_box">
          <p>General</p>
        </div>
        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>What is Yap?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                It is an anonymous polling app which has network of school &
                college students of who interact with each other through
                anonymously picking their friends on questions given to them and
                then the polls are sent to that friend which they have picked on
                the poll (Which is called YAPs) so the friends receive YAPs
                anonymously and then later have option to reveal it as well.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How to delete my information from Yap?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                If you wish to delete your information from Yap, you can do so
                by deleting your account. Once you take this action, all your
                personal information, including your contact list and location
                data, will be permanently removed from our database. We no
                longer retain any of your information once your account is
                deleted. Rest assured that your privacy and data security are
                our top priorities.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How much does it cost?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                With Yap, it's always free to interact with your friends but we
                offer upgrades/premium that make things more interesting.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>what is buy me a coffee on app?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                buy me a coffee is purely a donation to help the developers and
                support their initiative. the person supporting the developer
                will not get anything in return, but the developer appreciates
                people supporting him, and this is purely by choice, it is not a
                compulsion.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Is Yap private?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Yap cares intensely about user privacy. Account information is
                only used to run the app and help you connect with friends
                better. Yap will never sell or share personal information with
                third-parties, except as required by law or with our internal
                service providers. Read more in our Privacy Policy.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Is Yap safe?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Yap is designed to be a safe place to interact with friends.
                Users can only interact through polls and only with people they
                have accepted as a friend.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Where is Yap available?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Yap is currently only available in some regions. We are growing
                our server capacity to support more users.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>The App</p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How do I sign up?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>Yap is available on the App Store/Play Store here.</p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How do I add friends?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Swipe to the Add Friends screen. There you can add friends in
                four different ways:
                <br />
                <br />
                Your phone contacts
                <br />
                Your friends-of-friends
                <br />
                By Institution
                <br />
                By searching for a username
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Can I see who answered a poll about me?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                By default, Yap only shows the grade and gender of the person
                who voted for you, so everyone feels comfortable interacting
                with each other. However, for the ones that you're dying to
                know, you can reveal who sent it by upgrading to Premium Mode.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>What are Yaps?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Yaps are sent to you when you are selected in a poll. The yaps
                are pink when a girl picks you, blue when a boy picks you and
                purple when a non-binary person picks you. The polls that you’ve
                been selected for the most show up on your Profile, under the
                Top Yaps section.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>What are Coins?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Coins are earned by answering polls about your friends. Coins
                can be used to send a particular Yap to someone or put your name
                in your friends'—or your crush's—polls so they can vote on you
                more. However, for the ones who want more coins can buy more
                coins by upgrading to Premium mode. To redeem your coins, go to
                your Profile and tap shop.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How to send Yap to a specific person</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Yaps can be sent to a specific person without selecting them in
                a poll through coins. Go to the shop section in your profile,
                tap on 'Send Yap to a Specific Person,' then select the question
                you want to send. The person will receive this yap just like any
                other yap.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Premium Mode</p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>What is Premium Mode?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Premium Mode is a subscription that unlocks these features:
                <br />
                <br />
                5 Hints — See the first letter of someone who voted for you
                <br />2 Bonus Reveals — See the full name of someone who voted
                for you <br />
                Double Coins — Earn double coins when voting on polls <br />
                Extra 350 coins — Get 350 coins extra <br />
                Anonymous Mode — Send Polls 100% Anonymously
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How to buy Premium Mode?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Premium Mode can be purchased by tapping the button to see who
                likes you on your Inbox or in the profile section.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Is Premium Mode private?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Premium Mode is 100% private. Yap does not show which users have
                Premium Mode.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Account</p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>I can't find my friends. Help.</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Usually this means your contacts have not synced. If you see an
                alert to enable contacts, tap it to enable. You can also open
                the Settings app on your phone › select Yap › Enable Contacts.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How do I remove a friend?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>Go to your Profile, tap friends and then tap remove friend.</p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How do I unhide or unblock someone?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Go to your Profile and tap edit profile. Then under Account
                Settings, tap manage account. From there, you can reset your
                Hides and Blocks.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Why do I need a phone number and contacts?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Your phone number is used to log into your account and your
                contacts are used to find your friends. Your phone number or
                contacts are never shared with other users.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>What is location access used for?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Your location is never tracked or linked to your account. Yap
                only uses your location one-time to find nearby Institutions to
                join and to determine if Yap is available in your area. This
                helps us ensure that people only join Institutions that they are
                attending.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Are real names required?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                You should always use a name that your friends recognize so that
                your friends can vote for you and you don’t get mistaken for
                someone else. This means:
                <br />
                <br />
                Don’t use fake names <br />
                Don’t create multiple accounts <br />
                Don’t impersonate or sign up as a friend <br />
                Don’t join a Institution that you are not currently attending
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Someone is impersonating me. How do I report them?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                To report impersonation, open the user's profile › tap in the
                left corner › select report user. Provide as much information as
                you can in the input box. To expedite the process, you should
                also send an email to team@theyap.app with a photo of your ID
                and the phone number you used to sign up. We will review your
                report and follow up as soon as possible.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>My friend signed up with my phone number. How do I fix it?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We use your phone number to identify you so you should never
                sign up with a phone number that doesn’t belong to you. If you
                do, please logout of the app and sign up again with your own
                phone number.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>I had a negative experience on the app. How do I report it?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                We care deeply about our users and we strive to make our
                community safe and inclusive. If you see an account that is
                breaking the rules, tap on their profile to submit a report. You
                can also reach out to our team directly at team@theyap.app and
                share your feedback or experience. We would love to hear from
                you.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Why are my contacts in polls? How do I remove them?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Until you have enough friends, we use your contacts as
                placeholders in the polls so you can still play the game.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How do I log out?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Go to your profile and tap edit profile, then on Account
                Settings tap logout.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How to delete my account?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                Swipe over to your Profile and tap edit profile. Then under
                Account Settings, tap manage account. When you deactivate your
                account, your friends won't be able to find or vote on you.
                After 24 hours, your account will not be recoverable. If you'd
                like to reactivate your account before that happens, just login
                with your phone number.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>Institutions</p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How do I join my Institution?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                During signup, tap enable location and then pick your
                Institution and grade. Yap only uses your approximate location
                to find nearby Institutions and the data is never stored or
                linked to your identity. Please remember to only join an
                Institution that you are currently attending because you will
                only be able to change your Institution once. At this time, we
                only support selected Institutions.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>How to change name/gender/institution?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                You are allowed to change your Institution, grade or name once
                per account. You can change these by swiping to your Profile and
                tapping edit profile. If a change was made in error, you can
                contact us by tapping get help in the app.
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>My Institution is not listed. How do I add it?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                If your Institution is not appearing when searching, please
                email us at team@theyap.app and include the full name of your
                Institution, its city & state, and the abbreviation (e.g., RHS).
              </p>
            </div>
          </div>
        </div>

        <div className="op_inner_container_body">
          <div className="op_inner_container_box">
            <div className="op_inner_container_heading_box">
              <p>What age is Yap designed for?</p>
            </div>
            <div className="op_inner_container_para_box">
              <p>
                To protect the privacy of younger people, users under 13 years
                old should not use or download Yap. If you are a parent or
                guardian of a pre-teen, we advise setting controls on your
                minor's phone to prevent access to social media applications.
                Step-by-step instructions can be found here.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Faqpage;
